
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString'
import ShipmentTable from "@/components/import-data/ShipmentTable.vue";
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    ShipmentTable,
    loading: VueLoading,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    const isLoading = ref(false);
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;
    const startDateFilter = ref<Date>();
    const endDateFilter = ref<Date>();
    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("OrderEstimatedShipmentListRecordCreate");
    const canUpdate = checkPermissions("OrderEstimatedShipmentListRecordUpdate");
    const canDelete = checkPermissions("OrderEstimatedShipmentListRecordDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '出貨管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: true,
      canDelete: canDelete,
      multiselect: false,
      printConfig: { sheetName: '出貨管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '出貨管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'CreatedTime',
          title: '建立時間',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'Number',
          title: '編號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'RFNo',
          title: '退稅案件編號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'EDNo',
          title: '出口報單號碼',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('shipment/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('shipment/query') : undefined,
        save: model ? (params) => model.dispatch('shipment/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, height: 850, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        },
        {
          field: 'RefundTaxDateTime',
          title: '申請沖退稅日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'EDNo',
          title: '出口報單號碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        },
        {
          field: 'SalesDate',
          title: '銷售日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'SalesNumber',
          title: '銷售編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        },
        {
          field: 'CurrencyType',
          title: '幣別',
          span: 12,
          itemRender: { name: '$select', props: { clearable: true }, options: [
                    { value: 0, label: '台幣' },
                    { value: 1, label: '美金' },
                    { value: 2, label: '日幣' },
                    { value: 3, label: '人民幣' },
                    { value: 4, label: '歐元' },
                ]}
        },
        {
          field: 'ExchangeRate',
          title: '匯率',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入匯率', disabled: false } }
        },
        {
          field: 'RFNo',
          title: '退稅案件編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        },
        // {
        //   field: 'RefundTaxDocNo',
        //   title: '退稅案件編號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        // },
        // {
        //   field: 'ExportDocNo',
        //   title: '出口報單號碼',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入編號', disabled: false } }
        // },
        {
          field: 'CheckAmount',
          title: '核退金額',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'ActualIssuedAmount',
          title: '實際核發金額',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'TotleActualIssuedAmount',
          title: '實際核發總金額',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'PaymentDate',
          title: '收款日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'ContainerFee',
          title: '櫃費',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'VenueFee',
          title: '櫃場運費',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'DocumentFee',
          title: '文件費',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'ServicesFee',
          title: '推廣貿易服務費',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'ApproveDate',
          title: '放行日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'FOBPrice',
          title: '出口FOB價格',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入金額', disabled: false } }
        },
        {
          field: 'PaperOrElectronic',
          title: '紙本或電子',
          span: 12,
          itemRender: { name: '$select', props: { clearable: true }, options: [
                    { value: "紙本", label: '紙本' },
                    { value: "電子", label: '電子' },
                ]}
        },
        {
          title: '項目資料',
          span: 24,
          slots: { default: 'column-Items' }
        },
      ],
      rules: {
        Number: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      callback();
    }

    const itemsTable = ref<any>();
    const formSubmit = (row: any, submit: any) => {
      var items = itemsTable?.value.getData();
      if(items)
        row.Items = items;
      submit();
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async (row: any) => {
      try {
        isLoading.value = true;
        var data = await model!.dispatch('shipment/exportExcel', row.Id);
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "出貨資料.xlsx";
        link.click();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }
    //清表
    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/ExportDeclarationReportView?recordId=${row.Id}`)
    }
    //甲表一聯
    const exportWorksheetA = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReportView?recordId=${row.Id}`)
    }//甲表二聯
    const exportWorksheetB = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport2View?recordId=${row.Id}`)
    }
    //乙表
    const exportWorksheetC = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport3View?recordId=${row.Id}`)
    }  
    //乙表
    const exportWorksheetC2 = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport32View?recordId=${row.Id}`)
    }    
    //乙表
    const exportWorksheetC3 = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport33View?recordId=${row.Id}`)
    }      
    //丙表
    const exportWorksheetD = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport4View?recordId=${row.Id}`)
    }
    //甲表三聯
    const exportWorksheetE = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/OrderEstimatedShipmentReport22View?recordId=${row.Id}`)
    }
    const refresh = () => {
      grid.value.refresh();
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      initData,
      canCreate,
      itemsTable,
      formSubmit,
      exportExcel,
      isLoading,
      refresh,
      exportReport,
      exportWorksheetA,
      exportWorksheetB,
      exportWorksheetC,
      exportWorksheetD,
      exportWorksheetE,
      exportWorksheetC2,
      exportWorksheetC3,
      endDateFilter,
      startDateFilter,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
